import React from "react"
import styled from "styled-components"

import SEO from "./../components/seo"
import Profile from "./../components/profile"
import Footer from "../components/Footer"

const Privacy = () => {
  return (
    <>
      <SEO
        slug="/privacy/"
        title="Legal Agreements | MTG Builders, a Deck Builder app"
        description="Privacy Policy, Terms of Service and Cookie Policy for MTG Builders"
      />
      <Profile />
      <PageLayout>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "5rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          MTG Builders
        </MyText>
        <MyText
          as={"h1"}
          style={{
            "--font-size": "4rem",
            "--margin-bottom": "0rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          a MTG Deck Builder web app
        </MyText>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": "4rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          to build the future of Magic
        </MyText>
        <MyText
          as={"h2"}
          style={{
            "--font-size": "3.5rem",
            "--margin-bottom": "1.5rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Privacy Policy
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          I created MTG Builders with the intent of making a web app easy to
          use.
          <br />
          It's in the same spirit that the Privacy Policy is written in plain
          language.
          <br /> This web app takes advantage of already established services
          when dealing with sensitive data, so there won't be a lot to read here
          (yay!).
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Payments
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "2.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          To manage the subscriptions, I've partnered with PayPal. <br />
          PayPal takes a part of the profits and, in exchange, I don't have to
          deal with anything related to money transfers. <br />
          I sometimes forget where I've put my wallet, but I can't lose your
          credit card number simply because I don't have access to this
          information. <br />
          The beauty of delegation! <br />
          If you want to read more about PayPal application policies and
          guidelines,{" "}
          <a
            href="https://developer.paypal.com/docs/policiesAndGuidelines/"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here.
          </a>
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          User Data
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "2.5rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          FaunaDB is one of the landmarks in the serverless ecosystem and a
          great service to develop complex web applications. <br />
          Attribute-based access control (ABAC) allows a flexible, fine-grained
          strategy for managing identity-based operations within Fauna. <br />
          For example, users on the Rare tier have access to more features than
          users on the Uncommon Tier. <br />
          ABAC extends the default Fauna authentication and authorization
          mechanisms. <br />
          Ok, too many buzzwords... what does this mean for you?
          <br />
          Basically, Fauna encrypts and stores your credentials, and I don't
          have access to your password.
          <br /> Feel free to reach out to me if you want to ask me more or just
          geek about it!
        </MyText>
        <MyText
          as={"h4"}
          style={{
            "--font-size": "3rem",
            "--margin-bottom": ".7rem",
            "--text-shadow": "0px 7px 4px black",
            "--text-shadow-hover": "0px 7px 4px black",
          }}
        >
          Cookies and Analytics
        </MyText>
        <MyText
          as={"p"}
          style={{
            "--font-size": "2.2rem",
            "--margin-bottom": "1rem",
            "--text-shadow": "0px 4px 2px black",
            "--text-shadow-hover": "0px 4px 2px black",
          }}
        >
          To comply with the regulations governing cookies under the GDPR and
          the ePrivacy Directive, it's mandatory to receive users’ consent
          before using any cookies, except strictly necessary cookies.
          <br />
          Necessary cookies are those essential for browsing the website and
          using its features, such as accessing secure areas of the site.
          <br />
          Non-essential cookies are usually implemented to gather all sorts of
          info about users.
          <br />
          This information is then typically sold to third-party companies.
          <br />
          I don't ask for consent to sell your data because I simply don't sell
          your data.
          <br />
          From day one, I decided that MTG Builders would be ad-free, for
          everyone. This also makes the web app faster!
          <br />
          What about analytics? I've implemented Google Analytics to understand
          how people are discovering MTG Builders.
          <br />
          I did activate the anonymize_ip feature to request that all of the
          users' IP addresses are anonymized.
          <br />I don't know who you are, and I've also configured Google
          Analytics to respect the "Do Not Track" browser setting.
        </MyText>
        <Footer />
      </PageLayout>
    </>
  )
}

export default Privacy

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  /*   justify-content: center; */
  align-items: center;
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;
  max-width: 1400px;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`
